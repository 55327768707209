// httpClient.js
import axios from 'axios';

const PROD_BACK = 'https://whitelabelquote.cloobot.ai/backend'
// const PROD_BACK = 'http://whitelabelquote.cloobot.ai:5005'

const httpClient = axios.create({
  baseURL: PROD_BACK 
});



export const dynApi = (api) =>{
  const dynHttpClient = axios.create();
  return dynHttpClient;
}

export default httpClient;
export {PROD_BACK};
