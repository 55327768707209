import React, { useState, useEffect, useRef } from 'react';
import {
  Container,
  TextField,
  Button,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextareaAutosize,
  Avatar,
  Box
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import FeedbackIcon from '@mui/icons-material/Feedback';
import { v4 as uuidv4 } from 'uuid';
import './Chat.css';
import httpClient from './httpClient';

function Chat() {
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [sessionId, setSessionId] = useState('');
  const [openFeedback, setOpenFeedback] = useState(false);
  const [feedback, setFeedback] = useState({ rating: '', comment: '' });
  const messagesEndRef = useRef(null);

  useEffect(() => {
    // Generate or retrieve session ID from sessionStorage
    const storedSessionId = sessionStorage.getItem('session_id');
    if (storedSessionId) {
      setSessionId(storedSessionId);
    } else {
      const newSessionId = uuidv4();
      sessionStorage.setItem('session_id', newSessionId);
      setSessionId(newSessionId);
    }

    // Fetch chat history from backend
    const fetchChatHistory = async () => {
      try {
        const response = await httpClient.post('/previous_history', { 
          session_id: sessionId 
        });
        setMessages(response.data);
      } catch (error) {
        console.error('Error fetching chat history:', error);
      }
    };

    if (sessionId) {
      fetchChatHistory();
    }
  }, [sessionId]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (message.trim() === '') return;

    const newMessage = { user: 'You', text: message };
    // setMessages((prevMessages) => [...prevMessages, newMessage]);

    try {
      const res = await httpClient.post('/chat', { 
        message,
        session_id: sessionId 
      });
      const botMessage = { user: 'Bot', text: res.data.response };
      setMessages((prevMessages) => [...prevMessages, newMessage, botMessage]);
    } catch (error) {
      console.error('Error:', error);
    }

    setMessage('');
  };

  const handleFeedbackOpen = () => {
    setOpenFeedback(true);
  };

  const handleFeedbackClose = () => {
    setOpenFeedback(false);
  };

  const handleFeedbackSubmit = () => {
    // Submit feedback logic
    console.log('Feedback submitted:', feedback);
    setOpenFeedback(false);
  };

  return (
    <Container maxWidth="sm" className="chat-container">
      <Paper elevation={3} className="chat-box">
        <Box display="flex" justifyContent="space-between" alignItems="center" className="chat-header">
          <Typography variant="h5" gutterBottom>
            ChatAI
          </Typography>
          {/* <IconButton color="secondary" onClick={handleFeedbackOpen}>
            <FeedbackIcon />
          </IconButton> */}
        </Box>
        <List className="chat-history">
          {messages.map((msg, index) => (
            <ListItem key={index} className={msg.user === 'You' ? 'user-message' : 'bot-message'}>
              <Avatar className="avatar">{msg.user.charAt(0)}</Avatar>
              <ListItemText primary={msg.text} />
            </ListItem>
          ))}
          <div ref={messagesEndRef} />
        </List>
        <form onSubmit={handleSubmit} className="chat-form">
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Type your message here"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <Button type="submit" variant="contained" color="primary" endIcon={<SendIcon />} className="send-button">
            Send
          </Button>
        </form>
      </Paper>
      <Dialog open={openFeedback} onClose={handleFeedbackClose}>
        <DialogTitle>How would you rate this chat?</DialogTitle>
        <DialogContent>
          <RadioGroup
            aria-label="rating"
            name="rating"
            value={feedback.rating}
            onChange={(e) => setFeedback({ ...feedback, rating: e.target.value })}
          >
            <FormControlLabel value="good" control={<Radio />} label="Good" />
            <FormControlLabel value="bad" control={<Radio />} label="Bad" />
            <FormControlLabel value="not-sure" control={<Radio />} label="Not Sure" />
          </RadioGroup>
          <TextareaAutosize
            aria-label="comment"
            minRows={3}
            placeholder="Enter your comment here"
            style={{ width: '100%' }}
            value={feedback.comment}
            onChange={(e) => setFeedback({ ...feedback, comment: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFeedbackClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleFeedbackSubmit} color="primary">
            Submit Feedback
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default Chat;
